<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingSidebar></SettingSidebar>

                <div class="setting-content" id="company_setup">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                        <div class="section-title margin-deliver">
                            {{ $t("Candidate Apply Setting") }}
                            <v-popover offset="10" :disabled="false"  style="display:initial;">
                                <button class="ej__tooltip__button ml-1"> <i class="eicon e-question"></i> </button>
                                <template slot="popover">
                                    <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                    <div style="text-align:left;">
                                        <a v-close-popover href="https://easy.jobs/docs/configure-candidate-apply-settings" target="_blank">
                                            {{ $t(`Configure ‘Candidate Apply Settings’?`) }}
                                        </a>
                                    </div>

                                </template>
                            </v-popover>
                        </div>
                    </div>
                    <div class="form-box">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>{{ $t(`Login Options`) }}</label>
                                    <div>
                                        <label class="checkbox mr-4" v-for="(option, id) in loginOption">
                                            <input type="checkbox" :value="id" v-model="form.login_option">
                                            <span>{{ $t(option) }}</span>
                                        </label>
                                    </div>
                                    <error-message :message="errors['login_option']"/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <div class="company-powered_by">
                                        <label>{{ $t("Attach resume with email") }}</label>
                                        <div class="selection-box">
                                            <input type="checkbox" class="notify"
                                                   v-model="form.attach_resume_with_email"
                                                   value="true"
                                                   id="remove-cover-photo"
                                            />
                                            <label for="remove-cover-photo" class="power-by__remove">
                                                <span v-if="form.attach_resume_with_email">{{ $t('yes') }}</span>
                                                <span v-else>{{ $t('no') }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{ $t(`Custom Apply Fields`) }}</label>
                        </div>

                        <div class="row" v-if="customFields.length > 0">
                            <div class="col-md-6" v-for="(field, index) in customFields">
                                <div class="form-group">
                                    <label :for="`custom-field-${index}`" class="d-flex justify-content-between">
                                        {{ $t("Field") }} {{ (index + 1) }}*

                                        <button class="control-button control-button--danger has_bg_color" @click.prevent="removeField(index)">
                                            <div class="control-button__icon"><i class="eicon e-delete"></i></div>
                                        </button>
                                    </label>
                                    <input :id="`custom-field-${index}`" class="form-control" maxlength="50" :type="field.type" :placeholder="$t('field name')" v-model.trim="field.field_name"/>
                                    <error-message v-if="errorMessages.hasOwnProperty(index)" :message="errorMessages[index]"/>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex">
                            <button class="control-button control-button--info has_bg_color" @click.prevent="addNewField">
                                <div class="control-button__icon"><i class="eicon e-plus"></i></div>
                                <span>{{ $t('add new field') }}</span>
                            </button>
                        </div>
                    </div>


                    <div class="section-gap d-flex justify-content-start">
                        <submit-button :click="saveApplySetting" :loading="isLoading">{{ $t(`Save`) }}</submit-button>
                    </div>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
import DashboardLayout from "../../../layouts/DashboardLayout";
import SettingSidebar from "../../../components/company/SettingSidebar";
import client from "../../../app/api/Client";
import {UPDATE_COMPANY_DATA} from '../../../constants/action-type';
import {mapState, mapActions} from 'vuex';
import ErrorMessage from "../../../components/common/ErrorMessage";

export default {
    components: {
        ErrorMessage,
        DashboardLayout,
        SettingSidebar,
    },
    data() {
        return {
            form: {
                login_option: ['google', 'linkedin', 'email'],
                custom_fields: [],
                attach_resume_with_email: false
            },
            customFields: [],
            errors: {
                login_option: '',
                custom_fields: []
            },
            isLoading: false,
            loginOption: {},
            newCustomField: {
                id: null,
                type: 'text',
                field_name: '',
                error: ''
            },
            hasError: false,
            errorMessages : [],
        };
    },
    computed: {
        ...mapState(['company']),
    },
    methods: {
        ...mapActions([UPDATE_COMPANY_DATA]),
        async saveApplySetting() {
            if (!this.validate()) {
                return false;
            }
            this.isLoading = true;
            try {
                if (this.customFields) {
                    this.form.custom_fields = this.customFields.map(cf => ({id: cf.id, type: cf.type, field_name: cf.field_name}));
                }
                let {data: {data, message}} = await client().post('/company/setting/apply-setting', this.form);
                this.$toast.success(message);
            } catch (error) {
                this.showErrorText(error);
            }
            this.isLoading = false;
            await this.getApplySetting();
        },
        async getApplySetting() {
            try {
                let {data: {data: {login_option, all_options, custom_fields, attach_resume_with_email}}} = await client().get("/company/setting/apply-setting");
                if (login_option) {
                    this.form.login_option = login_option;
                }
                this.loginOption = all_options;
                if (custom_fields.length) {
                    this.customFields = custom_fields.map(cf => ({id: cf.id, type: cf.type, field_name: cf.title}));
                }
                this.form.attach_resume_with_email = attach_resume_with_email;
            } catch (e) {
            }
        },
        validate() {
            this.errors = {
                login_option: '',
                custom_fields: []
            };
            if (this.form.login_option.length === 0) {
                this.errors.login_option = "At least one login option is required.";
                return false;
            }
            this.errorMessages = [];
            if (!this.validateCustomFields()) {
                this.errorMessages = this.customFields.map(cf => cf.error);
                return false;
            }

            return true;
        },
        validateCustomFields() {
            let duplicate = 0;
            if (this.customFields.length > 0) {
                this.hasError = false;
                _.forEach(this.customFields, (cf, k) => {
                    cf.error = "";
                    let fieldName = cf.field_name.trim();
                    if (_.isNull(fieldName) || fieldName.length === 0) {
                        cf.error = "Please input field name.";
                        this.hasError = true;
                        return false;
                    }
                    let hasDupe = this.customFields.filter(field => {
                        if(field.field_name.trim().toLowerCase() === fieldName.toLowerCase()) {
                            return field;
                        }
                    }).length > 1;
                    if (hasDupe){
                        duplicate++;
                        if(duplicate>1){
                            cf.error = "Please input unique field name.";
                            this.hasError = true;
                            return false;
                        }
                    }
                });
            }

            return !this.hasError;
        },
        showErrorText(error) {
            if (typeof error == 'string') {
                return this.$toast.error(error);
            } else if (error.response !== undefined && error.response.status === 422) {/* validation error */

                if (typeof error.response.data.message == 'string') {

                    return this.$toast.error(error.response.data.message);/* System error */
                }
                this.errors = _helper.serializeValidationMessage(error.response.data.message);
                return this.$toast.error(this.$t(`Oops! Please check your input`));
            } else {
            }
        },
        addNewField() {
            this.errorMessages = [];
            if (!this.validateCustomFields()) {
                this.errorMessages = this.customFields.map(cf => cf.error);
                return false;
            }
            this.customFields.push({...this.newCustomField});
        },

        removeField(index) {
            let message = {
                title: this.$t("Confirmation"),
                body: this.$t("are you sure you wanted to delete this field?")
            };
            let dialogConfig = {
                okText: this.$t('yes'),
                cancelText: this.$t('no'),
            };
            this.$dialog.confirm(message, dialogConfig).then(() => {
                if(this.customFields[index].id) {
                    client().delete(`company/setting/${this.customFields[index].id}/apply-setting`)
                        .then(({data: {data, message}}) => {
                            this.customFields.splice(index, 1);
                            return true;
                        }).catch(({response : { data }}) => {
                        this.$toast.error(data.message);
                        return true;
                    });
                }else{
                    this.customFields.splice(index, 1);
                }
            });
        },
        toggleAttachResume() {
            this.form.attach_resume_with_email = !this.form.attach_resume_with_email;
        }
    },
    async mounted() {
        await this.getApplySetting();
    }
};
</script>


<style scoped>


input.notify[type=checkbox] {
    display: none;
}

input.notify + label {
    cursor: pointer;
    width: 64px;
    height: 30px;
    background: #FF5F74;
    display: inline-block;
    border-radius: 100px;
    position: relative;
    margin: auto;
}

input.notify + label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input.notify:checked + label {
    background: #60CE83;
}

input.notify:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

input.notify + label > span {
    color: #fff;
    text-transform: uppercase;
    float: right;
    padding: 8px 8px;
    font-size: .9rem;
    line-height: 1;
}

input.notify:checked + label > span {
    float: left;
}

.power-by__remove {
    height: 30px !important;
    margin: unset !important;
}

@media all and (max-width: 1399px) {
    .form-group .company-powered_by .selection-box label:not(.checkbox):not(.radio):not(.file-browse-button):not(.brand--color) {
        height: 30px !important;
    }
}
</style>